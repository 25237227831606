var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"saveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"saveModal","body-class":"position-static","centered":"","title":"Thêm quy đổi điểm","no-close-on-backdrop":""},on:{"show":_vm.onShow,"hide":_vm.onHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.onSave('hide')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Lưu lại ")],1)]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$bvModal.hide('saveModal')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}}),_vm._v(" Hủy ")],1)])],1)]},proxy:true}],null,true)},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"admissionFormId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Phương thức xét tuyển "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Phương thức xét tuyển","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.dataListAdmissionForm,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectAdmissionForm},model:{value:(_vm.targetItem.admissionFormId),callback:function ($$v) {_vm.$set(_vm.targetItem, "admissionFormId", $$v)},expression:"targetItem.admissionFormId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"admissionLanguageCertificateId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Chứng chỉ ngoại ngữ quy đổi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Chứng chỉ ngoại ngữ quy đổi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.admissionLanguageCertificateByAdmissionFormIds,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectAdmissionLanguageCertificate},model:{value:(_vm.targetItem.admissionLanguageCertificateId),callback:function ($$v) {_vm.$set(_vm.targetItem, "admissionLanguageCertificateId", $$v)},expression:"targetItem.admissionLanguageCertificateId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"admissionScoreTypeId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Loại điểm quy đổi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Loại điểm quy đổi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.dataAdmissionScores,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectAdmissionScoreType},model:{value:(_vm.targetItem.admissionScoreTypeId),callback:function ($$v) {_vm.$set(_vm.targetItem, "admissionScoreTypeId", $$v)},expression:"targetItem.admissionScoreTypeId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"admissionSubjectId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Môn học quy đổi điểm "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Môn học quy đổi điểm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"label","options":_vm.admissionSubjectIds,"reduce":function (option) { return option.value; }},on:{"input":_vm.selectAdmissionSubject},model:{value:(_vm.targetItem.admissionSubjectId),callback:function ($$v) {_vm.$set(_vm.targetItem, "admissionSubjectId", $$v)},expression:"targetItem.admissionSubjectId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"scoreCertificate"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Điểm chứng chỉ "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Điểm chứng chỉ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"scoreCertificate","disabled":"","name":"scoreCertificate","type":"number"},model:{value:(_vm.targetItem.scoreCertificate),callback:function ($$v) {_vm.$set(_vm.targetItem, "scoreCertificate", $$v)},expression:"targetItem.scoreCertificate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"scoreByExchange"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Điểm quy đổi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Điểm quy đổi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"scoreByExchange","disabled":"","name":"scoreByExchange","type":"number"},model:{value:(_vm.targetItem.scoreByExchange),callback:function ($$v) {_vm.$set(_vm.targetItem, "scoreByExchange", $$v)},expression:"targetItem.scoreByExchange"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-overlay',{attrs:{"no-wrap":"","variant":"white","spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm","show":_vm.isLoading}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }